import React from 'react';
import { makeStyles } from '@material-ui/core';
import MyCustomLogoFull from './logo/logo-pss.png';

const useStyles = makeStyles({
  logo: {
    width: 145, // Ajusta el ancho según tus necesidades
    height: 'auto', // Esto mantendrá la proporción de la imagen
  },
});

const LogoFull = () => {
  const classes = useStyles();
  return <img className={classes.logo} src={MyCustomLogoFull} alt="Logo" />;
};

export default LogoFull;